
import { Options, Vue } from "vue-class-component";
import { DocumentList } from "@/types/Document";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import Filter from "@/components/Filter.vue";
import { IEmployeeList } from "@/types/EmployeeList";
import moment from "moment";
import { notify } from "@/services/helpers";

@Options({
  name: "DocumentsList",
  components: {
    Breadcrumb,
    ConfirmationModal,
    PaginationComponent,
    Filter,
  },
})
export default class DocumentsList extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  documents: DocumentList[] = [];
  page = 0;
  perPage = 15;
  totalCount = 0;
  currentPage = 0;
  crumbs: any = ["Dashboard", "Manage Documents"];

  $refs!: {
    confirmDialogue: HTMLFormElement;
  };

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  savedSort = null;
  async sort(sortBy: any) {
    this.savedSort = sortBy;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/documents?SortBy=${sortBy}&PageIndex=${this.currentPage}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.documents = response.content.items;
        this.totalCount = response.content.totalCount;
      });
  }

  getFileType(name: string) {
    const fileType = name.split(".")[1];
    if (fileType == "doc" || fileType == "docx") {
      return "doc";
    } else if (fileType == "pdf") {
      return "pdf";
    }
    return "file";
  }

  goToPage(page: number) {
    this.currentPage = page;
    return this.workForceService
      .get<DocumentList[]>(
        `/documents?${
          this.savedSort ? "SortBy=" + this.savedSort + "&" : ""
        }PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.documents = response.content.items);
      });
  }

  async initialize() {
    await this.workForceService
      .get<DocumentList[]>(
        `/documents?PageIndex=${this.page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.documents = response.content.items;
      });
  }

  async created() {
    await this.initialize();
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  documentId = 0;
  async deleteDocument(id: number) {
    this.documentId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Delete Document?",
      message:
        "Are you sure you want to delete this Document? It cannot be undone.",
      okButton: "Delete",
      cancelButton: "Cancel",
      theme: "danger",
      type: "confirmation",
    });
    if (modal) {
      await this.okClick();
      this.documentId = 0;
    } else {
      this.documentId = 0;
      return false;
    }
  }

  okClick() {
    this.workForceService
      .delete(`/documents/${this.documentId}`)
      .then((response: any) => {
        if (!response.isError) {
          notify("Document deleted successfully.", "Success", "success");
          this.initialize();
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
  }

  async downloadDocument(id: number, fileName: string) {
    await this.workForceService
      .get<any>(`/documents/download?id=${id}`, false)
      .then((response: any) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.content]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
  }

  formatDate(createdOn: any) {
    return moment(new Date(createdOn)).format("Do-MMM-YYYY");
  }
}
